import React from "react"
import { Link, graphql } from "gatsby"
import { navigate } from "gatsby-link"

import Layout from "../components/layout"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Form({data}) {
  const [state, setState] = React.useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  
  return (
    <Layout>
      {/* <SEO title="Testform" /> */}
      <form
        name="unitMittelstandForm"
        method="post"
        action="/danke/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label for="bot">
            Don’t fill this out:{" "}
            <input name="bot-field" id="bot" onChange={handleChange} />
          </label>
        </p>
        <div className="form-row">
            <div className="form-group col-md-6">
                <input type="text" className="form-control" id="name" name="name" placeholder="Ihr Vor- und Nachname" onChange={handleChange} required />
            </div>
            <div className="form-group col-md-6">
                <input type="text" className="form-control" id="firma" name="firma" placeholder="Ihre Firma" onChange={handleChange} />
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <input type="email" className="form-control" id="email" name="email" placeholder="Ihre E-Mail" onChange={handleChange} required />
            </div>
            <div className="form-group col-md-6">
                <input type="tel" className="form-control" id="telephone" name="telephone" placeholder="Ihre Telefonnummer" onChange={handleChange} required />
            </div>
        </div>
        <h4 className="mb-2">Anmerkungen</h4>
        <div className="form-row">
                <textarea className="form-control" id="anmerkungen" name="anmerkungen" rows="4" placeholder="Sie möchten uns noch etwas mitteilen? Sehr gerne!" onChange={handleChange} required />
            </div>
        <div className="form-row">
            <div className="form-group col-md-12">
                <button type="submit" className="q-btn q-btn-primary d-inline mt-4">Senden</button>
            </div>
        </div>
        {data.allMdx.edges.map(({ node }) => {
					return (
            <div className="form-row">
              <div className="form-group col-md-12">
                  <input type="hidden" name={`${node.frontmatter.type}.active`} value={true} />
                  <textarea
                    className="form-control"
                    id={node.frontmatter.type}
                    name={`${node.frontmatter.type}.message`}
                    rows="4"
                    placeholder="Briefen Sie uns über Ihre Inhalte. Das könnte sein: Zielgruppe, Inhalt, Einsatzzweck ..." onChange={handleChange} />
              </div>
            </div>
          )
        })}
      </form>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx {
      edges {
        node {
          frontmatter {
            type
          }
        }
      }
    }
  }
`